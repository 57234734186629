import MySidebar from "./components/Sidebar";
import SidebarContext from "./contexts/SidebarContext";
import Default from "./layouts/Default";
import { GlobalStyleProj } from "./layouts/GlobalStyle";
import Home from "./views/Home";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import MainContext from "./contexts/MainContext";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <MainContext>
          <SidebarContext>
            <Route path="/" exact>
              <Default>
                <GlobalStyleProj />
                <MySidebar>
                  <></>
                </MySidebar>
                <Home />
              </Default>
            </Route>
          </SidebarContext>
        </MainContext>
      </Switch>
    </BrowserRouter>
  );
}

// const Wrapper = styled.div`
//   height: 200vh;
//   display: flex;
//   align-items: flex-end;
//   #teste {
//     height: 15px;
//     position: relative;
//     bottom: 0;
//   }
// `;

// function App() {
//   return (
//     <Wrapper>
//       <span onClick={() => smoothScrollTo(0, document.getElementById("teste").offsetTop)}>CLick</span>
//       <span id="teste"/>
//     </Wrapper>
//   );
// }

export default App;
