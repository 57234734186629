import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useMainContext } from '../../contexts/MainContext';

const containerStyle = {
  height: '45vh',
  margin: "1rem",
  border: "5px solid #461f00"
};

function MyMap() {
  const {config} = useMainContext()
  return (
    <LoadScript
      googleMapsApiKey="AIzaSyBA6CwrTEhAZQNmzQzraQ8g7k1rMUzRQ3I"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={config}
        zoom={config?.zoom}
      >
        <Marker position={config}></Marker>
      </GoogleMap>
    </LoadScript>
  )
}

export default React.memo(MyMap)