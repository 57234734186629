import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { galeria, imgUrl } from "../../services";

const Main = styled.div`
  .slick-prev,
  .slick-next {
    display: none !important;
  }

  .slick-dots {
    height: 5%;
    position: absolute;
    top: 90%;
    z-index: 1;
  }

  .slick-dots li button:before {
    font-size: 30px;
    line-height: 20px;

    width: 20px;
    height: 20px;
    opacity: 0.9;

    content: "○";
  }

  .slick-dots li.slick-active button:before {
    content: "●";
  }

  @media screen and (max-width: 600px) {
    .slick-dots {
      top: 80%;
    }
  }

`;

const Wrapper = styled.div`
  width: 100%;
  height: 600px;

  img {
    object-fit: cover;
    width: 100%;
  }
  
  @media screen and (max-width: 600px) {
    height: 220px;
  }

`;

function Banner() {
  const [banners, setBanners] = useState([]);

  const getBanners = useCallback(async () => {
    const res = await galeria.banners();
    setBanners(res.data);
  }, []);

  useEffect(() => {
    getBanners();
  }, [getBanners]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,

  };

  return (
    <Main>
      <Slider {...settings}>
        {banners.map((b, key) => (
          <Wrapper key={key}>
            <img src={imgUrl(b.imagem)} alt="banner" />
          </Wrapper>
        ))}
      </Slider>
    </Main>
  );
}

export default Banner;
