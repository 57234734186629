import styled from "styled-components";
import post1 from "../../assets/images/post1.png";
import post2 from "../../assets/images/post2.png";
import post3 from "../../assets/images/post3.png";

const Wrapper = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 50px 0px;

    @media screen and (max-width: 600px) {
        flex-wrap: wrap;
    }
`;

const Item = styled.div`
    
    width: 20%;
    margin: 10px 0px;
    img {
        width: 100%;
    }

    @media screen and (max-width: 600px) {
        width: 80%;
    }
`;


function Galery() {

    return (
        <Wrapper >
            <Item>
                <img src={post1} alt="..." />
            </Item>
            <Item>
                <img src={post2} alt="..." />
            </Item>
            <Item>
                <img src={post3} alt="..." />
            </Item>
        </Wrapper>
    );
}

export default Galery;