import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { galeria, imgUrl } from '../../services';

const CarolselWrapper = styled.div`
  .slick-thumb {
    position: relative;
    bottom: 0;
  }

  .slick-thumb li {
    width: 80px;
    height: 50px;
    border: 3px solid #461f00;
  }

  .slick-thumb li img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /></filter></svg>#filter');
    filter: grayscale(100%);
    object-fit: cover;
  }

  .slick-thumb li.slick-active img {
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" /></filter></svg>#filter');
    filter: grayscale(0);
  }
`;

const ImageWrapper = styled.div`
  text-align: center;
  img {
    width: 100%;
  }
`;

const Video = styled.iframe`
  width: 700px; 
  height: 400px;
  
  @media (max-width: 800px) {
    width: 600px; 
    height: 300px;
  }

  @media (max-width: 500px) {
    width: 250px;
    height: 150px
  }
`;

const Carousel = () => {
  const [images, setImages] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'slick-dots slick-thumb',
    customPaging(i) {
      return <img src={imgUrl(images[i].imagem)} alt="thumbnail" />;
    }
  };

  const getImages = useCallback(async () => {
    const res = await galeria.all();
    setImages(res.data)
  }, [])

  useEffect(() => {
    getImages();
  }, [getImages])

  return (
    <CarolselWrapper>
      <Slider {...settings}>
        {images.map((image, idx) => (
          <ImageWrapper key={idx}>
            {image.video !== 1 ? (
              <img src={imgUrl(image.imagem)} alt="Imagem da galeria" />
            ) : (
                <Video src={imgUrl(image.imagem)} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></Video>
              )}
          </ImageWrapper>
        ))}
      </Slider>
    </CarolselWrapper>
  );
};

export default Carousel;