import { useEffect, useState } from "react";

export function useWindowSize() {
  const [width, setWidth] = useState(0);
  const [height, setheight] = useState(0);
  useEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
      setheight(window.innerHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return { width, height };
}

export const mySetTimeOut = (exec, duration = 400) => {
  const startTime = new Date().getTime();
  const timer = setInterval(() => {
    const verify = new Date().getTime() - startTime;
    if (verify >= duration) {
      exec();
      clearInterval(timer);
    }
  }, 1000 / 60);
};

export function smoothScrollTo(endX = 0, endY = 0, duration = 400) {
  const startX = window.scrollX || window.pageXOffset;
  const startY = window.scrollY || window.pageYOffset;
  const distanceX = endX - startX;
  const distanceY = endY - startY;
  const startTime = new Date().getTime();
  // Easing function
  const easeInOutQuart = (time, from, distance, duration) => {
    if ((time /= duration / 2) < 1)
      return (distance / 2) * time * time * time * time + from;
    return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
  };

  const timer = setInterval(() => {
    const time = new Date().getTime() - startTime;
    const newX = easeInOutQuart(time, startX, distanceX, duration);
    const newY = easeInOutQuart(time, startY, distanceY, duration);
    if (time >= duration) {
      clearInterval(timer);
    }
    
    window.scroll(newX, newY);

  }, 1000 / 60); // 60 fps
}