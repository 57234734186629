import { createGlobalStyle } from "styled-components";

export const GlobalStyleProj = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    touch-action: manipulation;
  }

  html {
    overflow: auto;
  }

  body {
    overflow: hidden;
  }
  
  .font-love {
    font-family: Love;
  }
  
  .font-destacy {
    font-family: Destacy;
  }
  
  .font-afterglow {
    font-family: Afterglow;
  }
  
  .slick-prev,
  .slick-next {
    width: 40px;
    height: 40px;
  }
  
  .slick-prev::before,
  .slick-next::before {
    font-size: 42px;
    color: #fff;
  }
  
  .slick-prev {
    z-index: 3;
    left: 1%;
  }
  
  .slick-next {
    right: 1%;
  }
`;