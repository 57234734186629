// import React, { useState } from 'react';
import styled from "styled-components";
import area1 from "../../assets/images/area1.JPG";
import area2 from "../../assets/images/area2.JPG";
import area3 from "../../assets/images/area3.png";
import area4 from "../../assets/images/area4.JPG";

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 70vh 70vh;
  grid-template-columns: 50% 50%;
  grid-template-areas:
    "area1 area2"
    "area3 area4";

  .text-in-area {
    display: flex;
    padding: 40px;
    justify-content: flex-end;
    color: #fff;
    text-shadow: 0.1em 0.1em 5px #333;
    text-align: center;
    span {
      font-size: 25px;
      width: 60%;
    }
  }

  .area1 {
    grid-area: area1;
  }

  .area2 {
    grid-area: area2;
  }

  .area3 {
    grid-area: area3;
  }

  .area4 {
    grid-area: area4;
  }

  @media screen and (max-width: 600px) {
    grid-template-rows: 50vh 50vh 50vh 50vh;
    grid-template-columns: 100%;
    grid-template-areas: "area1" "area2" "area3" "area4";
    .text-in-area {
      padding: 20px;
      span {
        font-size: 15px;
        width: 60%;
      }
    }
  }
`;

function Delicias() {
  // const [itens] = useState([]);
  return (
    <Wrapper>
      <div
        className="area1 bg-image font-love text-in-area"
        style={{
          backgroundImage: "url(" + area1 + ") ",
        }}
      >
        <span>
          <h1>Casquinha Bro</h1>
          <p>
            Receita exclusiva, crocante e inconfundível. Produzida a todo
            momento, que cheiro!
          </p>
        </span>
      </div>
      <div
        className="area2 bg-image font-love text-in-area"
        style={{
          backgroundImage: "url(" + area2 + ") ",
        }}
      >
        <span>
          <h1>Milk Shake de Gelato</h1>
          <p>É diferente do de sorvete, e tem na Bro</p>
        </span>
      </div>
      <div
        className="area3 bg-image font-love text-in-area"
        style={{
          backgroundImage: "url(" + area3 + ") ",
        }}
      >
        <span>
          <h1>Gelato Tailandês</h1>
          <p>A intensidade na cremosidade e no sabor</p>
        </span>
      </div>
      <div
        className="area4 bg-image font-love text-in-area"
        style={{
          backgroundImage: "url(" + area4 + ") ",
        }}
      >
        <span>
          <h1>Mix na Pedra</h1>
          <p>O seu sabor preferido, ainda mais do seu jeito</p>
        </span>
      </div>
    </Wrapper>
  );
}

export default Delicias;
