import { useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../../assets/images/logo_bro.png";
import toggle from "../../assets/images/toggle.png";
import { useSidebarContext } from "../../contexts/SidebarContext";
import { smoothScrollTo } from "../../utils";

const Nav = styled.div`
  height: 45px;
  width: 100vw;
  position: fixed;
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: space-between;
  
  background-color: rgba(226, 214, 192, 0.7);

  .nav-brand {
    position: fixed;
    width: 12%;
    left: 20px;
    top: 10px;
    img {
      width: 100%;
    }
  }

  .nav-links {
    width: 50%;
    height: 45px;
    padding: 0px 40px;
  }

  .nav-links-items {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-links-item {
    color: #461f00;
    height: 100%;
    display: flex;
    align-items: center;
    font: 25px Destacy, sans-serif;
    cursor: pointer;
  }

  .nav-menu-toggle {
    display: none;
    height: 20px;
    width: 20px;
    margin: 10px;
    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 1000px) {
    width: 100vw;
    .nav-brand {
      width: 15%;
      left: 20px;
      top: 5px;
    }

    .nav-links {
      display: none;
    }

    .nav-menu-toggle {
      display: block;
    }
  }
`;

function Navbar() {
  const { items, toggleSidebar } = useSidebarContext();
  const [to, setTo] = useState(null);

  useEffect(() => {
    if (to) {
      smoothScrollTo(0, to, 1000);
      setTo(null);
    };
  }, [to]);

  return (
    <Nav>
      <div>
        <div className="nav-brand">
          <img
            className="nav-brand-brand"
            src={logo}
            alt="..."
          />
        </div>
      </div>
      <div className="nav-links">
        <div className="nav-links-items">
          {items.map((item, key) => (
            <span key={key} className="nav-links-item" onClick={() => {
              const to = document.getElementById(item.to).offsetTop - 70;
              setTo(to);
            }}>
              {item.name}
            </span>
          ))}
        </div>
      </div>
      <div className="nav-menu-toggle" onClick={toggleSidebar}>
        <img src={toggle} alt=".." />
      </div>
    </Nav>
  );
}

export default Navbar;
