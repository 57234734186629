import { useState } from "react";
import styled from "styled-components";
import cafeLeiteVaporizado from "../../assets/images/cafe-com-leite.png";
import carioca from "../../assets/images/carioca.png";
import chocBelga from "../../assets/images/chocolate-quente.png";
import capuccino from "../../assets/images/capuccino.png";
import expresso from "../../assets/images/expresso-curto.png";
import petitGateau from "../../assets/images/chocolate-quente.png";
import paozinhos from "../../assets/images/bolinhos.png";
import chips from "../../assets/images/chips.png";
import back from "../../assets/images/banner-caffe.png";
import { useWindowSize } from "../../utils";
import Slider from "react-slick";

const BackGround = styled.div`
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #464646;
    background-image: url(${props => props.back});
`;

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 0px 0px 0px 60px;

    @media screen and (max-width: 600px) {
        flex-wrap: nowrap;
        overflow: auto;
        padding: 0px;    
    }
`;

const Item = styled.div`

    color: #fff;
    display: flex;
    margin: 10px 50px;
    width: 20%;
    .img-wrapper {
        width: 50%;
        img {
            width: 100%;
        }
    }

    .desc {
        text-align: center;
        height: 100%;
        width: 40%;
        display:flex;
        align-items: center;
        justify-content: center;
        text-shadow: 0.1em 0.1em 5px #333;
        font: 20px Love, sans-serif;
        font-weight: 500;
    }

    @media screen and (max-width: 600px) {
        /* flex-basis: 100%; */
        position: inherit;
        margin: 5px;
        background-color: #c2c2c2;
        display: inline-block;
        width: 50%;

        .img-wrapper {
            width: 100%;
            img {
                width: 100%;
            }
        }
        
        .desc {
            font: 12px Love, sans-serif;
            width: 100%;
            height: 40%;
        }
    }
`;

const ToSlider = styled.div`
    .item {
        min-height: 40vh;
        display: flex;
        align-items: center;
        justify-content: center;

        .img-wrapper {
            width: 40%;
            img {
                width: 100%;
            }
        }
        .desc {
            color: #fff;
            width: 40%;
            text-shadow: 0.1em 0.1em 5px #333;
            text-align:center;
            font-size: 25px;
            line-height: 30px;
        }
    }
`;

function Items() {
    const [itens] = useState([
        {
            name: "Café com leite vaporizado",
            img: cafeLeiteVaporizado
        },
        {
            name: "Carioca",
            img: carioca
        },
        {
            name: "Chocolate quente Belga",
            img: chocBelga
        },
        {
            name: "Capuccino",
            img: capuccino
        },
        {
            name: "Expresso",
            img: expresso
        },
        {
            name: "Petit Gateau",
            img: petitGateau
        },
        {
            name: "Pãozinho de queijo Bro",
            img: paozinhos
        },
        {
            name: "Chips de casquinha Bro",
            img: chips
        },
    ])

    const { width } = useWindowSize();

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <BackGround back={back}>
            {width > 600 ?
                <Wrapper >
                    {itens.map((item, key) => <Item key={key}>
                        <div className="img-wrapper"><img src={item.img} alt="..." /></div>
                        <div className="desc">{item.name}</div>
                    </Item>)}
                </Wrapper> :
                <ToSlider>
                    <Slider {...settings}>
                        {itens.map((item, key) =>
                            <div style={{ margin: 0 }} key={key}>
                                <div className="item font-love" >
                                    <div className="img-wrapper"><img src={item.img} alt="..." /></div>
                                    <div className="desc">{item.name}</div>
                                </div>
                            </div>)}
                    </Slider>
                </ToSlider>}
        </BackGround>
    );
}

export default Items;