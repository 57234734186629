import { createContext, useState, useContext } from "react";

const mainContext = createContext();

export default function MainContext({ children }) {
    const [config, setConfig] = useState();

    return (
        <mainContext.Provider
            value={{
                config,
                setConfig,
            }}
        >
            {children}
        </mainContext.Provider>
    );
}

export function useMainContext() {
    return useContext(mainContext);
}