import styled from "styled-components";
import logo from "../../assets/images/inove.png";
import {useMainContext} from "../../contexts/MainContext";

const Wrapper = styled.div`
    height: 300px;
    background-color: #1f1f1f;
    display: grid;
    grid-template-rows: 200px 100px;
    grid-template-columns: 50% 50%;
    grid-template-areas: "info address"
                         "logo logo";
    z-index: 2;

    .footer-info {
        padding: 0px 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        grid-area: info;

        div {
            padding: 20px 0px; 
            text-align: start;
            width: 45%;
            color: #fff;
            font: 17px Afterglow, sans-serif;
        }
    }

    .footer-address {
        padding: 0px 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        grid-area: address;

        div {
            margin: 20px 0px; 
            text-align: end;
            width: 50%;
            color: #fff;
            font: 17px Afterglow, sans-serif;
        }
        
    }

    .footer-logo-cont {
        grid-area: logo;
        display: flex;
        justify-content: center;
        margin-top: 25px;
    }

    @media screen and (max-width: 600px) {
        grid-template-rows: 15vh 15vh;
        grid-template-columns: 50% 50%;
        grid-template-areas:    "address address"
                                "info info"
                                "logo logo";
        .footer-info {
            padding: 0px 70px;
            div {
                text-align: center;
                width: 100%;
                color: #fff;
                font: 12px Afterglow, sans-serif;
            }
        }

        .footer-address {
                padding: 0px 70px;
            div {
                margin: 5px 0px; 
                text-align: center;
                width: 100%;
                font: 12px Afterglow, sans-serif;
            }
            
        }
    }
`;

function Footer() {
    const {config} = useMainContext();
    return (
        <Wrapper>
            <div className="footer-info">
                <div>
                    Bro Gelatto
                </div>
                <div>
                    {config?.address}
                </div>
            </div>
            <div className="footer-address">
                <div>Horário de funcionamento</div>
                <div>
                    {config?.hours}
                </div>
                <div>
                    {config?.email}
                </div>
            </div>
            <div className="footer-logo-cont">
                <div className="footer-logo-wrapper">
                    <img src={logo} alt="footer-logo" />
                </div>
            </div>
        </Wrapper>
    );
}

export default Footer;