import React, { useState } from 'react';
import styled from 'styled-components';
import { contato } from '../../services';

const Wrapper = styled.div`
    background-color: #3d1e00;
    color: #fff;
    display: flex;
    justify-content:center;
    align-items: center;
    padding: 20px;
    font: 25px Love,sans-serif;
    form {
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content:center;

    }
    input{
        border: none;
        border-radius: 5px;
        height: 35px;
        margin: 5px;
    }

    button {
        width: 80px;
        height: 35px;
        color: #3d1e00;
        background-color: #ceb494;
        border: none;
        font-size: 20px;
        font-weight: 900;
        text-shadow: 0.1em 0.1em 10px #333;
        display: flex;
        align-items:center;
        justify-content:center;
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;
        form {
            flex-direction: column;
            margin-left: 0;
            width: 100%;
        }
        .form-group {
            width: 100%;
        }
        input {
            display: block;
            width: 100%;
            margin: 0;
        }
        button {
            display:block;
            width: 100%;
            margin-top: 10px;
        }
    }
`;

function FormContato() {
    const [wapp, setWapp] = useState("")
    const [email, setEmail] = useState("")

    const enviar = async (e) => {
        e.preventDefault()
        await contato.solicitar({ data: { telefone: wapp, email } });
        alert("Entraremos em contato em breve.");
        setEmail("");
        setWapp("");
    }

    return <Wrapper>
        <div className="text">
            Para receber nosas novidades deixe seus contatos de:
        </div>
        <form onSubmit={enviar}>
            <div className="form-group" >
                <label>Whatsapp:</label>
                <input value={wapp} onChange={e => setWapp(e.target.value)} />
            </div>
            <div className="form-group">
                <label>E-mail:</label>
                <input value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <button className="font-love">Enviar</button>
        </form>
    </Wrapper>;
}

export default FormContato;