import { useState } from "react";
import styled from "styled-components";
import emp1 from "../../assets/images/emp1.png";
import emp2 from "../../assets/images/emp2.png";
import emp3 from "../../assets/images/emp3.png";
import emp4 from "../../assets/images/emp4.png";
import emp5 from "../../assets/images/emp5.png";
import emp6 from "../../assets/images/emp6.png";
import emp7 from "../../assets/images/emp7.png";
import back from "../../assets/images/banner-empadas.png";
import donaCarolina from "../../assets/images/donacarolina.png";
import { useWindowSize } from "../../utils";
import Slider from "react-slick";

const BackGround = styled.div`
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #464646;
    background-image: url(${props => props.back});
`;

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* padding: 0px 0px 0px 60px; */

    @media screen and (max-width: 600px) {
        flex-wrap: nowrap;
        overflow: auto;
        padding: 0px;    
    }
`;

const Item = styled.div`
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 50px;
    width: 20%;
    
    .img-wrapper {
        width: 70%;
        img {
            width: 100%;
        }
    }

    .desc {
        width: 100%;
        display:flex;
        align-items: center;
        justify-content: center;
        text-shadow: 0.1em 0.1em 5px #333;
        font: 20px Love, sans-serif;
        font-weight: 300;
    }
`;

const ToSlider = styled.div`
    .item {
        min-height: 40vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .img-wrapper {
            width: 70%;
            img {
                width: 100%;
            }
        }

        .desc {
            color: #fff;
            width: 100%;
            text-align:center;
            font-size: 25px;
            text-shadow: 0.1em 0.1em 5px #333;
            line-height: 30px;
        }
    }
`;

function Items() {
    const [itens] = useState([
        {
            name: "Tomate Seco com Ricota",
            img: emp1,
        },
        {
            name: "Camarão",
            img: emp2,
        },
        {
            name: "Frango Cremoso",
            img: emp3,
        },
        {
            name: "Pizza",
            img: emp4,
        },
        {
            name: "",
            img: donaCarolina,
        },
        {
            name: "Quatro Queijos",
            img: emp5,
        },
        {
            name: "Frango Catupiry",
            img: emp6,
        },
        {
            name: "Carne com Queijo",
            img: emp7,
        },
        {
            name: "Calabresa com Azeitona",
            img: emp1,
        },
    ])

    const { width } = useWindowSize();

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <BackGround back={back}>
            {width > 600 ?
                <Wrapper>
                    {itens.map((item, key) => <Item key={key}>
                        <div className="img-wrapper"><img src={item.img} alt="..." /></div>
                        <div className="desc">{item.name}</div>
                    </Item>)}
                </Wrapper> :
                <ToSlider>
                    <Slider {...settings}>
                        {itens.map((item, key) =>
                            <div style={{ margin: 0 }} key={key}>
                                <div className="item font-love" >
                                    <div className="img-wrapper"><img src={item.img} alt="..." /></div>
                                    <div className="desc">{item.name}</div>
                                </div>
                            </div>)}
                    </Slider>
                </ToSlider>}
        </BackGround>
    );
}

export default Items;