import React from "react";
import Sidebar from "react-sidebar";
import { useSidebarContext } from "../../contexts/SidebarContext";
import toggle from "../../assets/images/toggle.png";
import styled from "styled-components";
import { smoothScrollTo } from "../../utils";

const Wrapper = styled.div`
  z-index: 6;
  .toggle {
  padding: 15px;
    display: flex;
    justify-content: flex-end;
    span {
      width: 20px;
      height: 20px;
      img {
        width: 100%;
      }
    }
  }

  .nav-links-items {
  padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .nav-links-item {
    list-style: none;
    color: #fff;
    font: 25px Destacy, sans-serif;
    a {
      text-decoration: none;
      color: inherit;
    }
  }
`;

function MySidebar({ children }) {
  const { openSidebar, toggleSidebar, items } = useSidebarContext();

  return (
    <Sidebar
      pullRight={true}
      sidebar={
        <Wrapper>
          <div className="toggle">
            <span onClick={toggleSidebar}>
              <img src={toggle} alt=".." />
            </span>
          </div>
          <ul className="nav-links-items">
            {/* <span>★</span> */}
            {items.map((item, key) => (
              <li key={key} onClick={() => {
                const to = document.getElementById(item.to).offsetTop;
                smoothScrollTo(0, to - 70);
                toggleSidebar()
              }} className="nav-links-item">
                {item.name}
              </li>
            ))}
          </ul>
        </Wrapper>
      }
      open={openSidebar}
      onSetOpen={toggleSidebar}
      styles={{
        sidebar: {
          zIndex: 5,
          position: "fixed",
          color: "white",
          background: "#461f00",
        },
      }}
    >
      {children}
    </Sidebar>
  );
}

export default MySidebar;
