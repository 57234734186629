import styled from "styled-components";
import back from "../../assets/images/social.png";
import { useMainContext } from "../../contexts/MainContext";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #464646;
    background-image: url(${props => props.back});

    .m-0 {
        margin: 0;
    }

    .siga {
        font-size: 20px;
    }

    .nick {
        font-size: 60px;
    }

    .hastag {
        font-size: 20px;
    }

    @media screen and (max-width: 600px) {
        /* flex-wrap: wrap; */
    }
`;

function Social() {
    const { config } = useMainContext();
    
    function openInsta(){
        window.open(config?.instagram)
    }

    return (
        <Wrapper back={back}>
            <div className="m-0 siga font-destacy">Siga-nos no Instagram</div>
            <div style={{cursor: "pointer"}} onClick={() => openInsta()} className="m-0 nick font-destacy">@brogelatto</div>
            <div className="m-0 hastag font-destacy">#tonabro</div>
        </Wrapper>
    );
}

export default Social;