import axios from "axios";

let base = null;

if (process.env.NODE_ENV === "production")
    base = "https://" + window.location.host + "/admin/";
else
    base = "http://localhost:8080/";

export const imgUrl = (image) => {
    const url = `${base}${image}`
    return url;
}

const api = axios.create({
    baseURL: base + "api",
});

export const galeria = {
    all: () => api.get("galeria"),
    banners: () => api.get("galeria?banner=1"),
}

export const info = {
    config: () => api.get("info"),
    sobre: () => api.get("info/sobre"),
}

export const contato = {
    solicitar: ({data}) => api.post("faleconosco/solicitar", data),
}

export const items = {
    all: ({config}) => api.get("items", config),
}