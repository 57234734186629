import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import gelato1 from "../../assets/images/gelatos-1.png";
import { imgUrl, items } from '../../services';
import Slider from 'react-slick';
// import { Container } from './styles';

const Wrapper = styled.div`
  .slick-prev::before,
  .slick-next::before {
    color: #461f00;
  }
  
  z-index: 4;

  @media screen and (min-width: 600px) {
    .slick-prev {
      left: 20%;
    }
    
    .slick-next {
      left: 30%;
    } 
  }
    .gelatos {
        margin-top: -4px;
        display: flex;

        .gelatos-1 {
          width: 40%;
          padding: 7px;
          text-align: center;

          h1 {
              font-size: 50px;
          }

          .desc {
              font: 15px Afterglow, sans-serif;
              margin: 20px;
              cursor: pointer;
          }
        }

        .gelatos-2 {
            width: 60%;
            background-color: #fffde6;

            h1 {
                font-size: 50px;
                color: #4d4c42;
                margin-left: 50px;
                margin-top: 50px;
            }
            .image-wrapper {
                right: 0;
                display: flex;
                justify-content: flex-end;
                max-height: 40vh;
                img {
                width: 60%;
                z-index: 1;
                object-fit: cover;
                object-position: top;
                }
            }
        }
    }
  @media screen and (max-width: 600px) {
    .gelatos {
      flex-direction: column;
      .gelatos-1 {
        width: 100%;
      }

      .gelatos-2 {
        width: 100%;
        text-align: center;
        h1 {
          font-size: 35px;
          margin-bottom: 50px;
          margin-left: 0;
        }
        .image-wrapper {
          img {
            width: 80%;
          }
        }
      }
    }
  }
`;

function Gelatos() {

  const [gelatos, setGelatos] = useState([]);
  const [caracteristica, setCaracteristica] = useState("novidade");

  const getGelatos = useCallback(async () => {
    const config = {
      params: {
        categoria: "gelato",
        caracteristica
      }
    }
    const res = await items.all({ config });
    setGelatos(res.data);
  }, [caracteristica]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  useEffect(() => {
    getGelatos();
  }, [getGelatos, caracteristica]);

  return (
    <Wrapper>
      <div className="gelatos" id="gelatos">
        <div
          style={{
            backgroundImage: "url(" + gelato1 + ") ",
          }}
          className="gelatos-1 bg-image col"
        >
          <h1 className="font-love">Difícil mesmo é escolher</h1>
          <div className="desc" onClick={() => setCaracteristica("novidade")}>{caracteristica === "novidade" ? <u>Novidades</u> : "Novidades"}</div>
          <div className="desc" onClick={() => setCaracteristica("preferido")}>{caracteristica === "preferido" ? <u>Preferidos</u> : "Preferidos"}</div>
          <div className="desc" onClick={() => setCaracteristica("zero_acucar")}>{caracteristica === "zero_acucar" ? <u>Zero Açucar</u> : "Zero Açucar"}</div>
          <div className="desc" onClick={() => setCaracteristica("zero_lactose")}>{caracteristica === "zero_lactose" ? <u>Zero Lactose</u> : "Zero Lactose"}</div>
          <div className="desc" onClick={() => setCaracteristica("vegano")}>{caracteristica === "vegano" ? <u>Veganos</u> : "Veganos"}</div>
          <div className="desc" onClick={() => setCaracteristica(null)}>{caracteristica === null ? <u>Todos os sabores</u> : "Todos os Sabores"}</div>
        </div>
        <div className="gelatos-2 col">
          <Slider {...settings}>
            {gelatos.map((item, key) =>
              <div key={key} >
                <h1 className="font-love">{item.nome}</h1>
                <div className="image-wrapper">
                  <img alt=".." src={imgUrl(item.imagem)} />
                </div>
              </div>
            )
            }
          </Slider>
        </div>
      </div>
    </Wrapper>
  );
}

export default Gelatos;