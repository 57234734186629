import { createContext, useState, useContext } from "react";

const mainContext = createContext();

export default function SidebarContext({ children }) {
    const [info, setInfo] = useState(null);
    const [openSidebar, setOpenSidebar] = useState(null);
    const toggleSidebar = () => setOpenSidebar(!openSidebar);

    const [items] = useState([
        {
            name: "Gelatos",
            to: "gelatos",
        },
        {
            name: "a Bro",
            to: "sobre",
        },
        {
            name: "+Delícias",
            to: "delicias",
        },
        {
            name: "Programas e Eventos",
            to: "eventos",
        },
        {
            name: "Contato",
            to: "contato",
        },
    ]);

    return (
        <mainContext.Provider
            value={{
                info,
                setInfo,
                openSidebar,
                setOpenSidebar,
                toggleSidebar,
                items
            }}
        >
            {children}
        </mainContext.Provider>
    );
}

export function useSidebarContext() {
    return useContext(mainContext);
}