import styled from 'styled-components';
import Helmet from 'react-helmet';
import Banner from '../components/Banner';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useCallback, useEffect } from 'react';
import { info } from '../services';
import { useMainContext } from '../contexts/MainContext';

const Container = styled.div`

`;

function Default({ children }) {

  const { setConfig } = useMainContext();

  const getConfig = useCallback(async () => {
    const res = await info.config();
    setConfig(res.data)
  }, [setConfig]);

  useEffect(() => {
    getConfig();
  }, [getConfig])

  return (
    <Container>
      <Helmet>
        <title>Brogelatto</title>
      </Helmet>
      <Navbar />
      <Banner />
      {children}
      <Footer />
    </Container>
  );
}

export default Default;
