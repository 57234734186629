import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { info } from "../../services";
import Carousel from "../CarrousselGalery";
import renderHTML from "react-render-html";

// import { Container } from './styles';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  .section-1 {
    width: 65%;
    img {
      width: 100%;
    }
  }

  .section-2 {
    width: 35%;
    .text {
      text-align: center;
      padding: 20px 60px;
      font: 15px Afterglow, sans-serif;
      color: #461f00;
      h1 {
        font: 40px Love;
      }
    }
    .map {
      margin: -30px 3% 3% 3%;
      height: 25%;
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    .section-1 {
      width: 100%;
    }

    .section-2 {
      width: 100%;
      padding: 10px;
    }
  }
`;

function Sobre() {
  const [sobre, setSobre] = useState({ titulo: "", conteudo: "" });

  const getSobre = useCallback(async () => {
    const res = await info.sobre();
    setSobre(res.data);
  }, []);

  useEffect(() => {
    getSobre();
  }, [getSobre]);

  return (
    <Wrapper>
      <span id="sobre" />
      <div className="section-1">
        <Carousel />
      </div>
      <div className="section-2">
        <div className="text">
          <h1>{sobre.titulo}</h1>
          {renderHTML(sobre.conteudo)}
        </div>
      </div>
    </Wrapper>
  );
}

export default Sobre;
