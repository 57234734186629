import styled from "styled-components";

import Items from "../components/Items";
import Galery from "../components/galery";
import Social from "../components/Social";
import Empadas from "../components/Empadas";
import FormContato from "../components/FormContato";
import Gelatos from "../components/Gelatos";
import Sobre from "../components/Sobre";
import Delicias from "../components/Delicias";
import Map from "../components/Map";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  
  .bg-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #464646;
  }
`;

function Home() {
  return (
    <Wrapper>
      <span id="gelatos"></span>
      <Gelatos/>
      <span id="sobre"></span>
      <Sobre/>
      <span id="mapa"></span>
      <Map/>
      <span id="delicias"></span>
      <Delicias/>
      <span id="items"></span>
      <Items />
      <span id="empadas"></span>
      <Empadas />
      <span id="contato"></span>
      <FormContato/>
      <span id="eventos"></span>
      <Galery/>
      <span id="social"></span>
      <Social />
    </Wrapper>
  );
}

export default Home;
